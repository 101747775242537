exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accoglienza-js": () => import("./../../../src/pages/accoglienza.js" /* webpackChunkName: "component---src-pages-accoglienza-js" */),
  "component---src-pages-attivita-js": () => import("./../../../src/pages/attivita.js" /* webpackChunkName: "component---src-pages-attivita-js" */),
  "component---src-pages-chisiamo-js": () => import("./../../../src/pages/chisiamo.js" /* webpackChunkName: "component---src-pages-chisiamo-js" */),
  "component---src-pages-contatti-js": () => import("./../../../src/pages/contatti.js" /* webpackChunkName: "component---src-pages-contatti-js" */),
  "component---src-pages-dove-js": () => import("./../../../src/pages/dove.js" /* webpackChunkName: "component---src-pages-dove-js" */),
  "component---src-pages-formsent-js": () => import("./../../../src/pages/formsent.js" /* webpackChunkName: "component---src-pages-formsent-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

